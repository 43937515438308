import { useContext, useState } from 'react';
import { ThemeContext } from '../ThemeProvider';
import styled from 'styled-components';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useSelector } from 'react-redux';

const RevenuHeader = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  color: ${({ theme }) => theme.text_primary};
`;

const Section = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
`;

const InfoGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
`;

const InfoTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.text_secondary};
  margin: 5px 0 5px 5px;

  @media (min-width: 600px) {
    font-size: 16px;
  }

  @media (min-width: 1000px) {
    font-size: 18px;
  }
`;

const InfoContent = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  gap: 10px;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 10px;
  padding: 5px;
  color: ${({ theme }) => theme.text_primary};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px 0 10px;
  font-weight: 400;
  font-size: 16px;

  @media (min-width: 600px) {
    font-size: 18px;
  }

  @media (min-width: 1000px) {
    font-size: 20px;
  }
`;

const Left = styled.div`
  flex: 2;
  display: flex;
  justify-content: start;
  flex-direction: row;
  gap: 40px;
  color: ${({ theme }) => theme.text_secondary};
`;

const Name = styled.div`
  flex: 1;
  display: flex;
  justify-content: start;
  flex-direction: row;
  padding-left: 3px;
  font-size: 20px;
  @media (min-width: 600px) {
    font-size: 24px;
  }

  @media (min-width: 1000px) {
    font-size: 30px;
  }
`;

const Center = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: row;
  font-size: 16px;
  @media (min-width: 600px) {
    font-size: 18px;
  }

  @media (min-width: 1000px) {
    font-size: 20px;
  }
`;

const Value = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (min-width: 600px) {
    font-size: 30px;
  }

  @media (min-width: 1000px) {
    font-size: 36px;
  }
`;

const ValuePrimary = styled.div`
  color: ${({ theme }) => theme.primary};
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (min-width: 600px) {
    font-size: 36px;
  }

  @media (min-width: 1000px) {
    font-size: 42px;
  }
`;

const Description = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.text_secondary};
`;

const TwinContent = styled.div`
  flex: 1;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 10px;
  padding: 5px;
  overflow: hidden;
  color: ${({ theme }) => theme.text_primary};
`;
const DialogRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DialogLabel = styled.div`
  color: ${({ theme }) => theme.text_secondary};
`;

const DialogValue = styled.div`
  color: ${({ theme }) => theme.text_primary};
`;

function RevenuePage() {
  const [totalNodesDetails, setTotalNodesDetails] = useState(false);
  const [yourNodesDetails, setYourNodesDetails] = useState(false);
  const [rewardsDetails, setRewardsDetails] = useState(false);
  const [yourTotalDetails, setYourTotalDetails] = useState(false);
  const { theme } = useContext(ThemeContext);
  const email = useSelector(state => state.user.email);
  const orderInfo = useSelector(state => {
    try {
      return JSON.parse(state.user.orderInfo);
    } catch (e) {
      return { own: null, lto: null, coOwn: null };
    }
  });
  const userName = (() => {
    if (orderInfo.own) {
      return orderInfo.own[0][1];
    }
    if (orderInfo.lto) {
      return orderInfo.lto[0][1];
    }
    if (orderInfo.coOwn) {
      return orderInfo.coOwn[0][0];
    }
    return '';
  })();
  const yourOwnNodes = (() => {
    if (!orderInfo.own) return 0;
    return orderInfo.own.filter(row => row[7] === 'Deployed').length;
  })();
  const yourLTONodes = (() => {
    if (!orderInfo.lto) return 0;
    return orderInfo.lto.filter(row => row[7] === 'Deployed').length;
  })();
  const yourCoOwnNodes = (() => {
    if (!orderInfo.coOwn) return 0;
    return orderInfo.coOwn.filter(row => row[7] === 'Deployed').length;
  })();
  // Data from cells B3, D3:D6 of Public Rev Calculator sheet
  const cloudRevenue = 65000;
  const ownNodes = 213, ltoNodes = 87, coOwnNodes = 2;
  const totalNodes = ownNodes + ltoNodes + coOwnNodes;
  // Computations from D13:D16
  const ownMultiplier = cloudRevenue / totalNodes;
  const ltoMultiplier = ownMultiplier * 0.5;
  const coOwnMultiplier = ownMultiplier / 30;
  // User-specific data
  const yourNodes = yourOwnNodes + yourLTONodes + yourCoOwnNodes;
  const yourOwnRevenue = yourOwnNodes * ownMultiplier;
  const yourLTORevenue = yourLTONodes * ltoMultiplier;
  const yourCoOwnRevenue = yourCoOwnNodes * coOwnMultiplier;
  const yourRevenue = yourOwnRevenue + yourLTORevenue + yourCoOwnRevenue;

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });
  const fmt = currencyFormatter.format.bind(currencyFormatter);

  return (
    <>
      <RevenuHeader theme={theme}>
        <Row>
          <Left theme={theme}>Hello, </Left>
        </Row>
        <Row>
          <Name>{userName}</Name>
        </Row>
      </RevenuHeader>
      <Section>
        <TwinContent theme={theme}>
          <Center>Nodes Online</Center>
          <Value>{totalNodes}</Value>
          <Description theme={theme}>Total Nodes</Description>
          <Button
            variant="contained"
            type="submit"
            fullWidth
            sx={{
              backgroundColor: theme.back_secondary,
              color: theme.text_primary,
              '&:hover': { backgroundColor: theme.primary },
              '&.Mui-disabled': {
                background: theme.back_secondary,
                color: theme.text_secondary,
              },
            }}
            onClick={() => setTotalNodesDetails(true)}>
            View Details
          </Button>
        </TwinContent>
        <TwinContent theme={theme}>
          <Center>Your Nodes</Center>
          <ValuePrimary theme={theme}>{yourNodes}</ValuePrimary>
          <Button
            variant="contained"
            type="submit"
            fullWidth
            sx={{
              backgroundColor: theme.back_secondary,
              color: theme.text_primary,
              '&:hover': { backgroundColor: theme.primary },
              '&.Mui-disabled': {
                background: theme.back_secondary,
                color: theme.text_secondary,
              },
            }}
            onClick={() => setYourNodesDetails(true)}>
            View Details
          </Button>
        </TwinContent>
      </Section>
      <InfoGroup>
        <InfoTitle theme={theme}>Revenue & Rewards</InfoTitle>
        <InfoContent theme={theme}>
          <Center>Projected Monthly Revenue</Center>
          <Value theme={theme}>$58,496.33</Value>
          <Description theme={theme}>October 2024</Description>
        </InfoContent>
      </InfoGroup>
      <Section>
        <TwinContent theme={theme}>
          <Center>Rewards</Center>
          <Value>{fmt(ownMultiplier)}</Value>
          <Description theme={theme}>Per Owned Node</Description>
          <Button
            variant="contained"
            type="submit"
            fullWidth
            sx={{
              backgroundColor: theme.back_secondary,
              color: theme.text_primary,
              '&:hover': { backgroundColor: theme.primary },
              '&.Mui-disabled': {
                background: theme.back_secondary,
                color: theme.text_secondary,
              },
            }}
            onClick={() => setRewardsDetails(true)}>
            View Details
          </Button>
        </TwinContent>
        <TwinContent theme={theme}>
          <Center>Your Total</Center>
          <ValuePrimary theme={theme}>{fmt(yourRevenue)}</ValuePrimary>
          <Button
            variant="contained"
            type="submit"
            fullWidth
            sx={{
              backgroundColor: theme.back_secondary,
              color: theme.text_primary,
              '&:hover': { backgroundColor: theme.primary },
              '&.Mui-disabled': {
                background: theme.back_secondary,
                color: theme.text_secondary,
              },
            }}
            onClick={() => setYourTotalDetails(true)}>
            View Details
          </Button>
        </TwinContent>
      </Section>
      <Dialog
        open={totalNodesDetails}
        onClose={() => setTotalNodesDetails(false)}
        PaperProps={{ style: { backgroundColor: theme.back_primary } }}>
        <DialogTitle style={{ color: theme.text_secondary }}>Total Nodes Details</DialogTitle>
        <DialogContent style={{ color: theme.text_primary, fontSize: 18 }}>
          <DialogRow>
            <DialogLabel>Own</DialogLabel>
            <DialogValue>{ownNodes}</DialogValue>
          </DialogRow>
          <DialogRow>
            <DialogLabel>LTO</DialogLabel>
            <DialogValue>{ltoNodes}</DialogValue>
          </DialogRow>
          <DialogRow>
            <DialogLabel>Co-Own</DialogLabel>
            <DialogValue>{coOwnNodes}</DialogValue>
          </DialogRow>
          <DialogRow>
            <DialogLabel>Total</DialogLabel>
            <DialogValue>{totalNodes}</DialogValue>
          </DialogRow>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setTotalNodesDetails(false)}
            style={{ color: theme.text_secondary }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={yourNodesDetails}
        onClose={() => setYourNodesDetails(false)}
        PaperProps={{ style: { backgroundColor: theme.back_primary } }}>
        <DialogTitle style={{ color: theme.text_secondary }}>Your Nodes Details</DialogTitle>
        <DialogContent style={{ color: theme.text_primary, fontSize: 18 }}>
          <DialogRow>
            <DialogLabel>Own</DialogLabel>
            <DialogValue>{yourOwnNodes}</DialogValue>
          </DialogRow>
          <DialogRow>
            <DialogLabel>LTO</DialogLabel>
            <DialogValue>{yourLTONodes}</DialogValue>
          </DialogRow>
          <DialogRow>
            <DialogLabel>Co-Own</DialogLabel>
            <DialogValue>{yourCoOwnNodes}</DialogValue>
          </DialogRow>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setYourNodesDetails(false)}
            style={{ color: theme.text_secondary }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={rewardsDetails}
        onClose={() => setRewardsDetails(false)}
        PaperProps={{ style: { backgroundColor: theme.back_primary, width: 500 } }}>
        <DialogTitle style={{ color: theme.text_secondary }}>Rewards Details</DialogTitle>
        <DialogContent style={{ color: theme.text_primary, fontSize: 18 }}>
          <DialogRow>
            <DialogLabel>Own</DialogLabel>
            <DialogValue>{fmt(ownMultiplier)}</DialogValue>
          </DialogRow>
          <DialogRow>
            <DialogLabel>LTO</DialogLabel>
            <DialogValue>{fmt(ltoMultiplier)}</DialogValue>
          </DialogRow>
          <DialogRow>
            <DialogLabel>Co-Own</DialogLabel>
            <DialogValue>{fmt(coOwnMultiplier)}</DialogValue>
          </DialogRow>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRewardsDetails(false)} style={{ color: theme.text_secondary }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={yourTotalDetails}
        onClose={() => setYourTotalDetails(false)}
        PaperProps={{ style: { backgroundColor: theme.back_primary, width: 500 } }}>
        <DialogTitle style={{ color: theme.text_secondary }}>Your Total Details</DialogTitle>
        <DialogContent style={{ color: theme.text_primary, fontSize: 16 }}>
          <DialogRow>
            <DialogLabel>OWN</DialogLabel>
            <DialogValue>{yourOwnNodes} Deployed @ {fmt(ownMultiplier)} = {fmt(yourOwnRevenue)}</DialogValue>
          </DialogRow>
          <DialogRow>
            <DialogLabel>LTO</DialogLabel>
            <DialogValue>{yourLTONodes} Deployed @ {fmt(ltoMultiplier)} = {fmt(yourLTORevenue)}</DialogValue>
          </DialogRow>
          <DialogRow>
            <DialogLabel>Co-Own</DialogLabel>
            <DialogValue>{yourCoOwnNodes} Deployed @ {fmt(coOwnMultiplier)} = {fmt(yourCoOwnRevenue)}</DialogValue>
          </DialogRow>
          <DialogRow>
            <DialogLabel>Your Total</DialogLabel>
            <DialogValue>{fmt(yourRevenue)}</DialogValue>
          </DialogRow>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setYourTotalDetails(false)}
            style={{ color: theme.text_secondary }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default RevenuePage;
